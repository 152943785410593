import { IProduct } from './product.store'
import { cloneDeep } from 'lodash'
export const grabDrinkCombo = {
  itemOptions: [
    {
      sku: 'V0401',
      description: 'Cà phê sữa đá 250ml',
      price: 0,
      countAs: 1
    },
    {
      sku: 'V0695',
      description: 'Hồng trà sữa(M)',
      price: 0,
      countAs: 1
    },
    {
      sku: 'V0713',
      description: 'Hồng trà latte (M)',
      price: 0,
      countAs: 1
    },
    {
      sku: 'V1050',
      description: 'Đường nâu trân châu sữa size M',
      price: 0,
      countAs: 1
    }
  ]
}
export const baeminDrinkCombo = {
  maxQuantity: 1,
  name: 'Đồ uống',
  itemOptions: [
    {
      sku: 'V1050',
      description: 'Đường nâu trân châu sữa size M',
      price: 0,
      countAs: 1
    },
    {
      sku: 'V0608',
      description: 'Trà sữa trân châu đường đen (M)',
      price: 0,
      countAs: 1
    },
    {
      sku: 'V0713',
      description: 'Hồng trà latte (M)',
      price: 0,
      countAs: 1
    }
  ]
}

export const vinIDrinkCombo = {
  itemOptions: [
    {
      sku: 'V0705',
      description: 'Trà chanh leo kim quất (M)',
      price: 0,
      countAs: 1
    },
    {
      sku: 'V0707',
      description: 'Lục trà chanh nhài (M)',
      price: 0,
      countAs: 1
    },
    {
      sku: 'V0888',
      description: 'Trà chanh gừng nóng',
      price: 0,
      countAs: 1
    },
    {
      sku: 'V1092',
      description: 'Trà chanh đào',
      price: 0,
      countAs: 1
    }
  ]
}

const cakeOptions = [
  {
    sku: 'V1399',
    description: 'Bánh bông lan trứng muối Bông Hoa size mini - 14x6cm',
    price: 230000,
    countAs: 1
  },
  {
    sku: 'V2665',
    description: 'Bánh bông lan trứng muối sốt phô mai size mini 14x6cm',
    price: 230000,
    countAs: 1
  },
  {
    sku: 'V2449',
    description: 'Bánh bông lan trứng muối việt quất size mini 14x6cm',
    price: 230000,
    countAs: 1
  },
  {
    sku: 'V1605',
    description: 'Bánh hồng kem chảy dâu Đà Lạt size mini - 13x6cm',
    price: 230000,
    countAs: 1
  },
  {
    sku: 'V1389',
    description: 'Bánh kem 3 Màu Pastel size mini - 13x6cm',
    price: 150000,
    countAs: 1
  },
  {
    sku: 'V1964',
    description: 'Bánh kem Baileys size mini - 13x6cm',
    price: 230000,
    countAs: 1
  },
  {
    sku: 'V1498',
    description: 'Bánh kem Choco Bear size mini - 13x6cm',
    price: 190000,
    countAs: 1
  },
  {
    sku: 'V1391',
    description: 'Bánh kem Cà Phê Cốt Dừa size mini - 13x6cm',
    price: 190000,
    countAs: 1
  },
  {
    sku: 'V1387',
    description: 'Bánh kem Galaxy Blue size mini - 13x6cm',
    price: 150000,
    countAs: 1
  },
  {
    sku: 'V1395',
    description: 'Bánh kem Green Tea size mini - 13x6cm',
    price: 190000,
    countAs: 1
  },
  {
    sku: 'V1499',
    description: 'Bánh kem Mango Chick size mini - 13x6cm',
    price: 190000,
    countAs: 1
  },
  {
    sku: 'V1963',
    description: 'Bánh kem Salted Caramel size mini - 13x7cm',
    price: 230000,
    countAs: 1
  },
  {
    sku: 'V1393',
    description: 'Bánh kem Triple Choco size mini - 13x6cm',
    price: 190000,
    countAs: 1
  },
  {
    sku: 'V1397',
    description: 'Bánh kem Xoài Dừa size mini - 13x6cm',
    price: 190000,
    countAs: 1
  },
  {
    sku: 'V1854',
    description: 'Bánh kem black forest size mini - 13x6cm',
    price: 230000,
    countAs: 1
  },
  {
    sku: 'V2502',
    description: 'Bánh kem bơ nho size mini - 13x6cm',
    price: 230000,
    countAs: 1
  },
  {
    sku: 'V2496',
    description: 'Bánh kem bơ size mini - 13x6cm',
    price: 190000,
    countAs: 1
  },
  {
    sku: 'V1857',
    description: 'Bánh kem cherry hồng pastel size mini - 13x6cm',
    price: 190000,
    countAs: 1
  },
  {
    sku: 'V1855',
    description: 'Bánh kem cherry tím size mini - 13x6cm',
    price: 230000,
    countAs: 1
  },
  {
    sku: 'V1856',
    description: 'Bánh kem hoa mặt cười size mini - 13x6cm',
    price: 190000,
    countAs: 1
  },
  {
    sku: 'V2348',
    description: 'Bánh kem mix hoa quả size mini - 13x6cm',
    price: 190000,
    countAs: 1
  },
  {
    sku: 'V2352',
    description: 'Bánh kem mixed berries size mini - 13x6cm',
    price: 230000,
    countAs: 1
  },
  {
    sku: 'V1608',
    description: 'Bánh kem màu loang dâu Đà Lạt size mini - 13x6cm',
    price: 190000,
    countAs: 1
  },
  {
    sku: 'V2347',
    description: 'Bánh kem nho xanh size mini - 13x6cm',
    price: 190000,
    countAs: 1
  },
  {
    sku: 'V2353',
    description: 'Bánh kem nho xanh việt quất size mini - 13x6cm',
    price: 230000,
    countAs: 1
  },
  {
    sku: 'V2349',
    description: 'Bánh kem nho xoài size mini - 13x6cm',
    price: 190000,
    countAs: 1
  },
  {
    sku: 'V2668',
    description: 'Bánh kem sữa chua dâu tây size mini 13x6cm',
    price: 230000,
    countAs: 1
  },
  {
    sku: 'V1602',
    description: 'Bánh kem sữa chua dâu Đà Lạt size mini - 13x6cm',
    price: 230000,
    countAs: 1
  },
  {
    sku: 'V1858',
    description: 'Bánh kem trái tim nhí size mini - 14x6cm',
    price: 190000,
    countAs: 1
  },
  {
    sku: 'V2345',
    description: 'Bánh kem xoài size mini - 13x6cm',
    price: 190000,
    countAs: 1
  },
  {
    sku: 'V2262',
    description: 'Bánh red velvet kem sữa chua dâu tây size mini - 14x6cm',
    price: 230000,
    countAs: 1
  },
  {
    sku: 'V2592',
    description: 'Bánh red velvet trái tim kem sữa chua hoa quả size mini 14x6cm',
    price: 230000,
    countAs: 1
  },
  {
    sku: 'V1504',
    description: 'Bánh red velvet tim kem sữa chua size mini - 14x6cm',
    price: 190000,
    countAs: 1
  },
  {
    sku: 'V1596',
    description: 'Bánh red velvet tròn dâu Đà Lạt size mini - 13x6cm',
    price: 230000,
    countAs: 1
  },
  {
    sku: 'V1536',
    description: 'Bánh sinh nhật order size mini - 13x6cm',
    price: 230000,
    countAs: 1
  },
  {
    sku: 'V1599',
    description: 'Bánh socola dâu Đà Lạt size mini - 13x6cm',
    price: 230000,
    countAs: 1
  },
  {
    sku: 'V1506',
    description: 'Bánh sữa chua hoa quả size mini - 13x6cm',
    price: 190000,
    countAs: 1
  },
  {
    sku: 'V1850',
    description: 'Bánh tiramisu size mini - 14x4cm',
    price: 275000,
    countAs: 1
  },
  {
    sku: 'V1444',
    description: 'Bánh vẽ sinh nhật size mini - 13x6cm',
    price: 190000,
    countAs: 1
  },
  {
    sku: 'V2436',
    description: 'Bông lan trứng kem phô mai size mini - 14x6cm',
    price: 230000,
    countAs: 1
  },
  {
    sku: 'V2430',
    description: 'Bông lan trứng muối trái tim size mini - 14x6cm',
    price: 275000,
    countAs: 1
  },
  {
    sku: 'V1401',
    description: 'Mousse Chanh Leo size mini - 14x6cm',
    price: 230000,
    countAs: 1
  },
  {
    sku: 'V2484',
    description: 'Mousse bơ size mini - 14x6cm',
    price: 275000,
    countAs: 1
  },
  {
    sku: 'V2490',
    description: 'Mousse bơ sữa chua size mini - 14x6cm',
    price: 275000,
    countAs: 1
  },
  {
    sku: 'V2571',
    description: 'Mousse cà phê cốt dừa size mini 14x6cm',
    price: 230000,
    countAs: 1
  },
  {
    sku: 'V1848',
    description: 'Mousse sữa chua dâu tây size mini - 14x6cm',
    price: 275000,
    countAs: 1
  },
  {
    sku: 'V2577',
    description: 'Mousse matcha yogurt size mini 14x6cm',
    price: 230000,
    countAs: 1
  },
  {
    sku: 'V2620',
    description: 'Mousse măng cụt size mini 14x6cm',
    price: 275000,
    countAs: 1
  },
  {
    sku: 'V2626',
    description: 'Mousse măng cụt sữa dừa size mini 14x6cm',
    price: 275000,
    countAs: 1
  },
  {
    sku: 'V1551',
    description: 'Mousse socola size mini - 14x6cm',
    price: 230000,
    countAs: 1
  },
  {
    sku: 'V2414',
    description: 'Mousse sữa chua việt quất size mini - 14x6cm',
    price: 275000,
    countAs: 1
  },
  {
    sku: 'V2583',
    description: 'Mousse việt quất socola size mini 14x6cm',
    price: 275000,
    countAs: 1
  },
  {
    sku: 'V1849',
    description: 'Mousse xoài size mini - 14x6cm',
    price: 230000,
    countAs: 1
  }
]

const savorCombos: IProduct[] = [
  {
    sku: 'V2471',
    description: 'Combo Savor - Bánh mì thịt nướng mật ong + Trà Ô long kim quất (M)',
    normalPrice: 53000,
    category: 'bread',
    subCat: 'bread-combo',
    isCombo: true,
    comboElements: [
      {
        name: 'Bánh mì',
        itemOptions: [
          {
            sku: 'V0287',
            description: 'Bánh mì thịt nướng mật ong',
            countAs: 1
          }
        ],
        maxQuantity: 1
      },
      {
        name: 'Đồ uống',
        itemOptions: [
          {
            sku: 'V2333',
            description: 'Trà Ô Long kim quất (M)',
            countAs: 1
          }
        ],
        maxQuantity: 1
      }
    ]
  },
  {
    sku: 'V2472',
    description: 'Combo Savor - Bánh mì bò sốt tiêu + Trà Ô long kim quất (M)',
    normalPrice: 53000,
    category: 'bread',
    subCat: 'bread-combo',
    isCombo: true,
    comboElements: [
      {
        name: 'Bánh mì',
        itemOptions: [
          {
            sku: 'V0098',
            description: 'Bánh mì bò sốt tiêu',
            countAs: 1
          }
        ],
        maxQuantity: 1
      },
      {
        name: 'Đồ uống',
        itemOptions: [
          {
            sku: 'V2333',
            description: 'Trà Ô Long kim quất (M)',
            countAs: 1
          }
        ],
        maxQuantity: 1
      }
    ]
  },
  {
    sku: 'V2473',
    description: 'Combo Savor - Bánh mì gà sốt teriyaki + Trà Ô long kim quất (M)',
    normalPrice: 48000,
    category: 'bread',
    subCat: 'bread-combo',
    isCombo: true,
    comboElements: [
      {
        name: 'Bánh mì',
        itemOptions: [
          {
            sku: 'V0096',
            description: 'Bánh mì gà sốt teriyaki',
            countAs: 1
          }
        ],
        maxQuantity: 1
      },
      {
        name: 'Đồ uống',
        itemOptions: [
          {
            sku: 'V2333',
            description: 'Trà Ô Long kim quất (M)',
            countAs: 1
          }
        ],
        maxQuantity: 1
      }
    ]
  },
  {
    sku: 'V9916',
    description: 'Combo Savor - Bánh mì gà sốt teriyaki + Trà Ô long chanh leo (M)',
    normalPrice: 45000,
    category: 'bread',
    subCat: 'bread-combo',
    isCombo: true,
    comboElements: [
      {
        name: 'Bánh mì',
        itemOptions: [
          {
            sku: 'V0096',
            description: 'Bánh mì gà sốt teriyaki',
            countAs: 1
          }
        ],
        maxQuantity: 1
      },
      {
        name: 'Đồ uống',
        itemOptions: [
          {
            sku: 'V2331',
            description: 'Trà Ô Long chanh leo (M)',
            countAs: 1
          }
        ],
        maxQuantity: 1
      }
    ]
  },
  {
    sku: 'V9094',
    description: 'Combo Savor - Bánh mì thịt nướng mật ong + Nước chanh leo (M)',
    normalPrice: 55000,
    category: 'bread',
    subCat: 'bread-combo',
    isCombo: true,
    comboElements: [
      {
        name: 'Bánh mì',
        itemOptions: [
          {
            sku: 'V0287',
            description: 'Bánh mì thịt nướng mật ong',
            countAs: 1
          }
        ],
        maxQuantity: 1
      },
      {
        name: 'Đồ uống',
        itemOptions: [
          {
            sku: 'V9081',
            description: 'Nước chanh leo (M)',
            countAs: 1
          }
        ],
        maxQuantity: 1
      }
    ]
  }
]
const nowCombos: IProduct[] = [
  // now
  {
    sku: 'V9097',
    description: 'Combo SPF - Xôi gà nấm + Trà xanh kim quất (M)',
    normalPrice: 60000,
    category: 'combo',
    subCat: 'now-combo',
    isCombo: true,
    comboElements: [
      {
        name: 'Xôi',
        itemOptions: [
          {
            sku: 'V8501',
            description: 'Xôi gà nấm',
            countAs: 1
          }
        ],
        maxQuantity: 1
      },
      {
        name: 'Nước',
        itemOptions: [
          {
            sku: 'V9083',
            description: 'Trà xanh kim quất (M) mới',
            countAs: 1
          }
        ],
        maxQuantity: 1
      }
    ]
  },
  {
    sku: 'V2649',
    description: 'Combo bánh nước 2 người (ShopeeFood)',
    normalPrice: 105000,
    category: 'combo',
    subCat: 'now-combo',
    isCombo: true,
    comboElements: [
      {
        name: 'Bánh mì 1',
        itemOptions: [
          {
            sku: 'V0287',
            description: 'Bánh mì thịt nướng mật ong',
            countAs: 1
          }
        ],
        maxQuantity: 1
      },
      {
        name: 'Bánh mì 2',
        itemOptions: [
          {
            sku: 'V0096',
            description: 'Bánh mì gà sốt teriyaki',
            countAs: 1
          }
        ],
        maxQuantity: 1
      },
      {
        name: 'Đồ uống 1',
        itemOptions: [
          {
            sku: 'V2331',
            description: 'Trà Ô Long chanh leo (M)',
            countAs: 1
          }
        ],
        maxQuantity: 1
      },
      {
        name: 'Đồ uống 2',
        itemOptions: [
          {
            sku: 'V2333',
            description: 'Trà Ô Long kim quất (M)',
            countAs: 1
          }
        ],
        maxQuantity: 1
      }
    ]
  },
  {
    sku: 'V2652',
    description: 'Combo Bánh mì thịt nướng mật ong + Bánh mì gà sốt teriyaki',
    normalPrice: 65000,
    category: 'combo',
    subCat: 'now-combo',
    isCombo: true,
    comboElements: [
      {
        name: 'Bánh mì 1',
        itemOptions: [
          {
            sku: 'V0287',
            description: 'Bánh mì thịt nướng mật ong',
            countAs: 1
          }
        ],
        maxQuantity: 1
      },
      {
        name: 'Bánh mì 2',
        itemOptions: [
          {
            sku: 'V0096',
            description: 'Bánh mì gà sốt teriyaki',
            countAs: 1
          }
        ],
        maxQuantity: 1
      }
    ]
  },
  {
    sku: 'V8311',
    description: 'Combo bánh mì thịt nướng mật ong (to) + cafe sữa đá (ShopeeFood)',
    normalPrice: 50000,
    category: 'combo',
    subCat: 'now-combo',
    isCombo: true,
    comboElements: [
      {
        name: 'Bánh mì',
        itemOptions: [
          {
            sku: 'V0287',
            description: 'Bánh mì thịt nướng mật ong',
            countAs: 1
          }
        ],
        maxQuantity: 1
      },
      {
        name: 'Đồ uống',
        itemOptions: [
          {
            sku: 'V0401',
            description: 'Cà phê sữa đá 250ml',
            countAs: 1
          }
        ],
        maxQuantity: 1
      }
    ]
  },
  {
    sku: 'V8312',
    description: 'Combo bánh mì trứng thịt pate + cafe sữa đá (ShopeeFood)',
    normalPrice: 50000,
    category: 'combo',
    subCat: 'now-combo',
    isCombo: true,
    comboElements: [
      {
        name: 'Bánh mì',
        itemOptions: [
          {
            sku: 'V2862',
            description: 'Bánh mì trứng thịt pate',
            countAs: 1
          }
        ],
        maxQuantity: 1
      },
      {
        name: 'Đồ uống',
        itemOptions: [
          {
            sku: 'V0401',
            description: 'Cà phê sữa đá 250ml',
            countAs: 1
          }
        ],
        maxQuantity: 1
      }
    ]
  },
  {
    sku: 'V8313',
    description: 'Combo bánh mì trứng thịt + cafe sữa đá (ShopeeFood)',
    normalPrice: 50000,
    category: 'combo',
    subCat: 'now-combo',
    isCombo: true,
    comboElements: [
      {
        name: 'Bánh mì',
        itemOptions: [
          {
            sku: 'V2861',
            description: 'Bánh mì trứng thịt',
            countAs: 1
          }
        ],
        maxQuantity: 1
      },
      {
        name: 'Đồ uống',
        itemOptions: [
          {
            sku: 'V0401',
            description: 'Cà phê sữa đá 250ml',
            countAs: 1
          }
        ],
        maxQuantity: 1
      }
    ]
  },
  {
    sku: 'V9093',
    description: 'Combo SPF - Bánh mì TNMO + Trà xanh kim quất (L)',
    normalPrice: 55000,
    category: 'combo',
    subCat: 'now-combo',
    isCombo: true,
    comboElements: [
      {
        name: 'Bánh mì',
        itemOptions: [
          {
            sku: 'V0287',
            description: 'Bánh mì thịt nướng mật ong',
            countAs: 1
          }
        ],
        maxQuantity: 1
      },
      {
        name: 'Đồ uống',
        itemOptions: [
          {
            sku: 'V9084',
            description: 'Trà xanh kim quất (L)',
            countAs: 1
          }
        ],
        maxQuantity: 1
      }
    ]
  }
]
const goCombos: IProduct[] = [
  {
    sku: 'V2652',
    description: 'Combo Bánh mì thịt nướng mật ong + Bánh mì gà sốt teriyaki',
    normalPrice: 65000,
    category: 'combo',
    subCat: 'gojek-combo',
    isCombo: true,
    comboElements: [
      {
        name: 'Bánh mì 1',
        itemOptions: [
          {
            sku: 'V0287',
            description: 'Bánh mì thịt nướng mật ong',
            countAs: 1
          }
        ],
        maxQuantity: 1
      },
      {
        name: 'Bánh mì 2',
        itemOptions: [
          {
            sku: 'V0096',
            description: 'Bánh mì gà sốt teriyaki',
            countAs: 1
          }
        ],
        maxQuantity: 1
      }
    ]
  }
]
const grabCombos: IProduct[] = [
  // Grab
  {
    sku: 'V9096',
    description: 'Combo Grab - Xôi gà nấm + Nước chanh leo (M)',
    normalPrice: 60000,
    category: 'combo',
    subCat: 'grab-combo',
    isCombo: true,
    comboElements: [
      {
        name: 'Xôi',
        itemOptions: [
          {
            sku: 'V8501',
            description: 'Xôi gà nấm',
            countAs: 1
          }
        ],
        maxQuantity: 1
      },
      {
        name: 'Nước',
        itemOptions: [
          {
            sku: 'V9081',
            description: 'Nước chanh leo (M)',
            countAs: 1
          }
        ],
        maxQuantity: 1
      }
    ]
  },
  {
    sku: 'V9095',
    description: 'Combo Grab - Bánh mì gà xé + Trà xanh kim quất (M)',
    normalPrice: 50000,
    category: 'combo',
    subCat: 'grab-combo',
    isCombo: true,
    comboElements: [
      {
        name: 'Bánh mì',
        itemOptions: [
          {
            sku: 'V8503',
            description: 'Bánh mì gà xé (to)',
            countAs: 1
          }
        ],
        maxQuantity: 1
      },
      {
        name: 'Nước',
        itemOptions: [
          {
            sku: 'V9083',
            description: 'Trà xanh kim quất (M) mới',
            countAs: 1
          }
        ],
        maxQuantity: 1
      }
    ]
  }
]
export const combos: IProduct[] = [
  {
    sku: 'V1408',
    description: 'Combo 2 bánh sinh nhật',
    normalPrice: 0,
    category: 'birthday-cake',
    subCat: 'cake-combo',
    isCombo: true,
    comboElements: [
      {
        name: '1',
        itemOptions: cloneDeep(cakeOptions),
        maxQuantity: 1
      },
      {
        name: '2',
        itemOptions: cloneDeep(cakeOptions),
        maxQuantity: 1
      }
    ]
  },
  ...savorCombos,
  ...grabCombos,
  ...nowCombos,
  ...goCombos
]
