import { IOrderItem, TOrderPlatform } from '../model/order.store'
import { IVariant } from '../model/product.store'
import { modifierStore, IModifier } from '../model/modifier.store'
import { isAfter, isBefore } from 'date-fns'

export function getSelectedVariant(item: IOrderItem): IVariant | undefined {
  let selectedVariant
  if (item.variants && item.variants.length > 0) {
    selectedVariant =
      item.selectedVariant || item.variants.find(variant => variant.sku === item.sku)
  }
  return selectedVariant
}

export function getPlatformPrice(
  item: IOrderItem | IVariant,
  orderPlatform: TOrderPlatform
): number {
  if (item.orderPlatforms) {
    const matchOrderPlatform = item.orderPlatforms.find(
      _orderPlatform => _orderPlatform.code === orderPlatform
    )
    if (matchOrderPlatform) {
      if (matchOrderPlatform.salePrice) {
        return matchOrderPlatform.salePrice
      }
      if (matchOrderPlatform.normalPrice) {
        return matchOrderPlatform.normalPrice
      }
    }
  }

  return item.salePrice || item.normalPrice
}
export function getOriginalPlatformPrice(
  item: IOrderItem | IVariant,
  orderPlatform: TOrderPlatform
): number {
  if (item.orderPlatforms) {
    const matchOrderPlatform = item.orderPlatforms.find(
      _orderPlatform => _orderPlatform.code === orderPlatform
    )
    if (matchOrderPlatform) {
      if (matchOrderPlatform.normalPrice) {
        return matchOrderPlatform.normalPrice
      }
    }
  }
  return item.normalPrice
}

export function getModifierPrice(modifier: IModifier, orderPlatfrom: TOrderPlatform) {
  if (modifier.orderPlatforms && modifier.orderPlatforms.length && orderPlatfrom) {
    const matchOrderPlatform = modifier.orderPlatforms.find(x => x.code === orderPlatfrom)
    if (matchOrderPlatform) {
      if (matchOrderPlatform.normalPrice) {
        return matchOrderPlatform.normalPrice
      }
    }
  }
  return modifier.price
}

export function getItemSoldPrice({
  item,
  orderPlatform,
  orderType,
  withoutModifier = false,
  totalForDiscount = false
}: {
  item: IOrderItem
  orderPlatform: TOrderPlatform
  orderType: string
  withoutModifier?: boolean
  totalForDiscount?: boolean
}): number {
  if (item.category === 'gift' && totalForDiscount) {
    return 0
  }
  const selectedVariant = getSelectedVariant(item)

  let modifierPrice = item.modifiers
    ? item.modifiers.reduce((result, modifier) => {
        const matchDefaultModifier = item.defaultModifierSkus
          ? item.defaultModifierSkus.find(sku => sku === modifier.sku)
          : null
        if (
          ['NOW', 'GRAB_FOOD', 'GO_FOOD', 'LOSHIP', 'BAEMIN'].includes(orderPlatform) &&
          modifier.sku === 'V1046'
        ) {
          return result
        }

        const modifierPrice = getModifierPrice(modifier, orderPlatform)
        return (
          Math.max(
            (modifierPrice || 0) * modifier.quantity -
              (matchDefaultModifier ? modifierPrice || 0 : 0),
            0
          ) + result
        )
      }, 0)
    : 0

  const discountForNotSelectedModifier = item.defaultModifierSkus
    ? item.defaultModifierSkus.reduce((result, sku) => {
        const matchModifier = modifierStore.modifiers.find(modifier => modifier.sku === sku)
        const matchItemModifier = item.modifiers.find(modifier => modifier.sku === sku)
        if (matchModifier && !matchItemModifier && matchModifier.modifierSetCode === 'xoi') {
          return result + (matchModifier.price || 0)
        }
        return result
      }, 0)
    : 0

  modifierPrice = modifierPrice - Math.max(discountForNotSelectedModifier, 0)
  if (item.comboId && item.comboName && item.sku !== 'V1408') {
    const result = 0 + modifierPrice
    if (item.comboName === 'Combo 2 bánh sinh nhật') {
      return result
    }
    if (item.category !== 'birthday-cake') {
      return result
    }
  }
  if (withoutModifier) {
    modifierPrice = 0
  }
  let soldPrice =
    (selectedVariant
      ? getPlatformPrice(selectedVariant, orderPlatform)
      : getPlatformPrice(item, orderPlatform)) + modifierPrice
  if (item.sku === 'V1408') {
    console.log('util', soldPrice)
  }
  if (item.hasSaltedEgg) {
    soldPrice += 10000
  }
  return Math.round(soldPrice / 10) * 10
}
export function getOriginalPrice(
  item: IOrderItem,
  orderPlatform: TOrderPlatform,
  orderType: string
): number {
  const selectedVariant = getSelectedVariant(item)

  let modifierPrice = item.modifiers
    ? item.modifiers.reduce((result, modifier) => {
        const matchDefaultModifier = item.defaultModifierSkus
          ? item.defaultModifierSkus.find(sku => sku === modifier.sku)
          : null

        const modifierPrice = getModifierPrice(modifier, orderPlatform)
        return (
          Math.max(
            (modifierPrice || 0) * modifier.quantity -
              (matchDefaultModifier ? modifierPrice || 0 : 0),
            0
          ) + result
        )
      }, 0)
    : 0

  const discountForNotSelectedModifier = item.defaultModifierSkus
    ? item.defaultModifierSkus.reduce((result, sku) => {
        const matchModifier = modifierStore.modifiers.find(modifier => modifier.sku === sku)
        const matchItemModifier = item.modifiers.find(modifier => modifier.sku === sku)
        if (matchModifier && !matchItemModifier && matchModifier.modifierSetCode === 'xoi') {
          return result + (matchModifier.price || 0)
        }
        return result
      }, 0)
    : 0

  modifierPrice = modifierPrice - Math.max(discountForNotSelectedModifier, 0)

  const orginalPrice =
    (selectedVariant
      ? getOriginalPlatformPrice(selectedVariant, orderPlatform)
      : getOriginalPlatformPrice(item, orderPlatform)) + modifierPrice
  return Math.round(orginalPrice / 10) * 10
}

export function dayStringBetween(startStr: string, endStr: string) {
  return isAfter(new Date(), new Date(startStr)) && isBefore(new Date(), new Date(endStr))
}
