import React, { useContext } from 'react'
import { intersection } from 'lodash'
import { Menu, Button } from 'semantic-ui-react'
import { RootStoreContext } from '../../../model/root-store'
import styles from './CurrentOrder.module.scss'
import { ModalPayment } from './ModalPayment'
import { handleAgentErrorResponse } from '../../../libs/error-handler'
import { toast } from 'react-toastify'
import { FormattedCurrency } from '../../../components/FormattedCurrency'
import {
  getJarTetBuy6Get1Quantity,
  isBillTotalDiscountValid,
  drinkDiscountMap,
  getDrinkDiscountQuantity,
  validGiftSharkTankDealFirework,
  checkValidGiftFlag
} from '../../../libs/bulk-discount'
import { observer } from 'mobx-react-lite'

export const CurrentOrderActions = observer(function CurrentOrderActions() {
  const rootStore = useContext(RootStoreContext)
  const orderStore = rootStore.orderStore
  const { canUseCurrentOutlet } = rootStore.authenticationStore
  const loggedInShift = rootStore.currentShiftStore.loggedInShift

  async function handleSendOrder() {
    try {
      await orderStore.sendOrder()
    } catch (e) {
      handleAgentErrorResponse(e)
      return
    }

    toast.success('Đã tạo Order')
  }

  async function handleCreateAndDoneOrder() {
    try {
      await orderStore.createAndDoneOrder()
    } catch (e) {
      handleAgentErrorResponse(e)
      return
    }
  }

  const buttons = loggedInShift
    ? [
        <Button
          key="done"
          positive
          color="green"
          onClick={handleCreateAndDoneOrder}
          disabled={orderStore.saving}
        >
          Done
        </Button>
      ]
    : canUseCurrentOutlet
    ? [
        <Button positive key="save" onClick={handleSendOrder} disabled={orderStore.saving}>
          Lưu Order
        </Button>
      ]
    : []

  let alertMessage = 'Mỗi đơn hàng bất kỳ được tặng 01 x V8777 Sing-gum sampling doublemint'

  const { giftNum } = getJarTetBuy6Get1Quantity(orderStore.items)
  if (giftNum > 0) {
    alertMessage = `Được tặng ${giftNum} Bộ túi và hộp đựng bánh Tết Sum Vầy 6 ngăn (chiếc)!`
  }
  const outlet = rootStore.currentShiftStore.currentShift
    ? rootStore.currentShiftStore.currentShift.outlet
    : null
  if (
    orderStore.computedCustomer &&
    outlet &&
    validGiftSharkTankDealFirework(orderStore.items, outlet, orderStore.computedCustomer)
  ) {
    if (alertMessage) {
      alertMessage += ' >>>>>> '
    }
    alertMessage += `Được tặng 03 x Bóng nổ pháo giấy hình súng V2615`
  }
  if (checkValidGiftFlag(orderStore.onlineOrder, orderStore.orderPlatform, orderStore.items)) {
    if (alertMessage) {
      alertMessage += ' >>>>>> '
    }
    alertMessage += `Được tặng 01 x V9142 Cờ đỏ sao vàng cầm tay`
  }
  if (isBillTotalDiscountValid(orderStore.items, orderStore.orderPlatform, orderStore.orderType)) {
    if (orderStore.orderPlatform === 'GRAB_FOOD') {
      if (alertMessage) {
        alertMessage += ' >>>>>> '
      }
      alertMessage += `Được tặng 1 x Sữa Dutch Lady V2340`
    }
    if (orderStore.orderPlatform === 'NOW') {
      if (alertMessage) {
        alertMessage += ' >>>>>> '
      }
      alertMessage += `Được tặng 1 x Pepsi 245ml (ShopeeFood) V2570 `
    }
  }

  let drinkAlertMessage = ''
  const drinkDiscountQuantity = getDrinkDiscountQuantity(orderStore.items)
  if (drinkDiscountQuantity) {
    // drinkAlertMessage += `Được giảm giá các món nước sau: `
  }

  return orderStore.computedItems.length > 0 ? (
    <Menu attached="bottom" className={styles['actions']}>
      {alertMessage ? (
        <div className={styles['actions__alert']}>
          <p className={styles['actions__alert-text']}>{alertMessage}</p>
        </div>
      ) : null}
      {drinkAlertMessage && orderStore.orderPlatform === 'SAVOR_SHOP' ? (
        <div className={styles['actions__alert']}>
          <p className={styles['actions__alert-text']}>
            {drinkAlertMessage}{' '}
            <span style={{ fontSize: 'medium' }}>
              ( Số lượng - <strong>{drinkDiscountQuantity}</strong>)
            </span>
            <br />
            {Object.values(drinkDiscountMap).map((item, index) => {
              return (
                <p key={index} className={styles['actions__alert-text']} style={{ margin: 0 }}>
                  - <strong>{item.description}</strong> sale còn{' '}
                  <strong>
                    <FormattedCurrency value={item.salePrice} />
                  </strong>
                  <br />
                </p>
              )
            })}
          </p>
        </div>
      ) : null}
      <Menu.Item
        as={() => (
          <ModalPayment buttonActions={buttons} orderStore={orderStore}>
            <Button color="green" fluid style={{ marginRight: 0 }}>
              Nhận <FormattedCurrency value={orderStore.cashTotal} />
            </Button>
          </ModalPayment>
        )}
      />
    </Menu>
  ) : null
})
